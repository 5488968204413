<template>
  <button
    :disabled='loading || success || localError || disableSubmit'
    :class="stylesForSize(small) + stylesForColor(color, localError )"
    class="rounded-[6px] text-center pointer transition-all duration-200 flex flex-row justify-center items-center gap-1"
  >
    <svg
      v-if="localError"
      class="stroke-black fill-black"
      :width="small ? 18 : 20"
      :height="small ? 18 : 20"
      viewBox="0 0 256 256">
      <path
        d="M124,144V104a4,4,0,0,1,8,0v40a4,4,0,0,1-8,0Zm109.30273,65.98291a19.79613,19.79613,0,0,1-17.32617,10.00928H40.02344a19.9998,19.9998,0,0,1-17.30957-30.019L110.69043,37.981a20.00012,20.00012,0,0,1,34.61914,0l87.97656,151.99218A19.798,19.798,0,0,1,233.30273,209.98291Zm-6.94043-16.00244L138.38574,41.98828a12.00037,12.00037,0,0,0-20.77148,0L29.6377,193.98047a12.00033,12.00033,0,0,0,10.38574,18.01172H215.97656a12.00033,12.00033,0,0,0,10.38574-18.01172ZM128,172a8,8,0,1,0,8,8A7.99977,7.99977,0,0,0,128,172Z"
        stroke-width="8"
      />
    </svg>
    <span v-if="localError"
          :class="{ 'text-sm': small }">{{ props.errorMessage ? props.errorMessage : $t('error.generic') }}</span>

    <LoadingSpinner v-else-if="loading" size="xs" />
    <span v-if="loading" :class="{ 'text-sm': small }">&nbsp;</span>
    <span v-else-if="success">
      {{ $t('buttonsubmit.done') }}
    </span>
    <span v-else-if="!localError" :class="{ 'text-sm': small }">
        {{ title ? title : $t('buttonsubmit.submit') }}
    </span>
  </button>
</template>
<script setup lang="ts">
const props = defineProps({
  small: { type: Boolean, required: false, default: false },
  loading: { type: Boolean, required: false, default: false },
  error: { type: Boolean, required: false, default: false },
  errorMessage: { type: String, required: false, default: null },
  title: { type: String, required: false, default: null },
  color: {
    type: String as () => 'bright' | 'dark' | 'white' | 'red' | 'whiteOnBlue',
    required: false,
    default: 'bright'
  },
  disableSubmit: { type: Boolean, required: false, default: false }
});

const success = ref(false);
const localError = ref<boolean>(props.error);

watch(
  () => props.loading,
  (value) => {
    if (value == false && !props.error) {
      success.value = true;
      setTimeout(() => {
        success.value = false;
      }, 2000);
    }
  }
);

watch(
  () => props.error,
  (value) => {
    localError.value = value;
    setTimeout(() => {
      localError.value = false;
    }, 2000);
  }
);

function stylesForSize(small: boolean) {
  if (small) {
    return 'text-sm leading-[18px] py-0.5 px-2 ';
  } else {
    return 'leading-[20px] py-1 px-3 ';
  }
}

function stylesForColor(color: 'bright' | 'dark' | 'white' | 'red' | 'whiteOnBlue', hasError: boolean) {

  var result = '';

  switch (color) {
    case 'bright':
      result = 'bg-theme-primary text-theme-font hover:bg-black bright';
      break;
    case 'dark':
      result = 'bg-black text-white hover:bg-theme-primary dark';
      break;
    case 'white':
      result = 'bg-white text-black hover:bg-theme-secondary white';
      break;
    case 'whiteOnBlue':
      result = 'bg-white text-black hover:bg-black white';
      break;
    case 'red':
      result = 'bg-theme-bad hover:bg-black red';
      break;
  }

  result += hasError ? ' disabled:bg-theme-bad disabled:hover:text-black' : ' disabled:bg-theme-font-hint disabled:hover:text-black';

  return result;
}
</script>
<style scoped lang="postcss">

button.dark {
  span {
    @apply text-white;
  }
}

button {
  g {
    @apply fill-theme-font transition-all duration-200;
  }

  g.white {
    @apply fill-black transition-all duration-200;
  }

  span {
    @apply fill-theme-font transition-all duration-200;
  }
}

button:hover {

  g {
    @apply fill-white;
  }

  g.white {
    @apply fill-white;
  }

  span {
    @apply text-white;
  }
}

button:hover:disabled {

  g {
    @apply fill-theme-font;
  }

  g.white {
    @apply fill-theme-font;
  }

  span {
    @apply text-theme-font;
  }
}

</style>
